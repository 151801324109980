import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { PageBottomWrapper, PageWrapper } from '../../components/page';
import { ButtonFullWidth } from '../../components';
import { IconWithText } from '../../components/icon.with.text';

export const PaymentSuccess: React.FC = () => {
  const { state } = useLocation();
  // state?.paymentStatus
  // state?.paymentIntentId
  // state?.orderId

  return (
    <>
      <PageWrapper>
        <IconWithText
          icon="success"
          header="Sėkmingai užsisakėte treniruotes"
          secondary="Jeigu negalėsite atvykti, prašome atšaukti treniruotę iš anksto."
        />
      </PageWrapper>
      <PageBottomWrapper>
        <ButtonFullWidth name="Peržiūrėti užsakymą" path={`/order/${state.orderId}`}></ButtonFullWidth>
      </PageBottomWrapper>
    </>
  );
};
