import React from 'react';
import styles from './location.editor.module.scss';
import { PageTitle, PageWrapper } from '../../components/page';
import { ButtonFullWidth, LocationItem } from '../../components';

export const LocationEditor = () => {
  function removeLocation(id: string) {
    console.log('id', id);
  }
  return (
    <PageWrapper className={styles.paymentContainer}>
      <PageTitle backButton>Lokacijos</PageTitle>
      <LocationItem
        location={{
          id: '1',
          displayName: 'Sportgates',
        }}
        removeLocation={removeLocation}
      />
      <LocationItem
        location={{
          id: '2',
          displayName: 'Lemon Gym',
        }}
        removeLocation={removeLocation}
      />

      <ButtonFullWidth name="Pridėti naują lokacija" size="small" path="/location/create" styleType="outlined" />
    </PageWrapper>
  );
};
