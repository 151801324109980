import React from 'react';
import styles from './field.module.scss';
import classNames from 'classnames';

interface IField {
  icon: React.ReactNode;
  text: string | React.ReactNode;
  size: 'small' | 'big';
}

export const Field: React.FC<IField> = ({ icon, text, size }) => {
  return (
    <div className={styles.field}>
      <i>{icon}</i>
      <div className={classNames(styles.fieldText, styles[size])}>{text}</div>
    </div>
  );
};
