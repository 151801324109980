import classNames from 'classnames';
import styles from './text.input.module.scss';
import React from 'react';

export const TextInput = React.forwardRef(
  ({ className, title, errorText, afterIcon, beforeIcon, ...restProps }: any, ref) => (
    <div className={styles.textInputWrapper}>
      <div className={styles.textInputName}>{title}</div>
      <div className={styles.inputWithIcon}>
        {beforeIcon && <div className={classNames(styles.icon, styles.beforeIcon)}>{beforeIcon}</div>}
        <input
          ref={ref}
          className={classNames(styles.textInput, className, {
            [styles.withAfterIcon]: Boolean(afterIcon),
            [styles.withBeforeIcon]: Boolean(beforeIcon),
          })}
          {...restProps}
        />
        {afterIcon && <div className={classNames(styles.icon, styles.afterIcon)}>{afterIcon}</div>}
      </div>
      {errorText && <div className={styles.textError}>{errorText}</div>}
    </div>
  )
);
