import { Link } from 'react-router-dom';
import styles from './dropdown.menu.module.scss';
import axios from 'axios';

const loggedInList = (logOut: any) => (
  <div className={styles.menuItems}>
    <Link className={styles.menuItem} to={'/profile'}>
      Profilis
    </Link>
    <Link className={styles.menuItem} to={'/trainings'}>
      Treniruotės
    </Link>
    <Link className={styles.menuItem} to={'/orders'}>
      Mokėjimai
    </Link>
    <a onClick={logOut} className={styles.menuItem}>
      Atsijungti
    </a>
  </div>
);

const notLoggedInList = (logIn: any) => (
  <div className={styles.menuItems}>
    <a onClick={logIn} className={styles.menuItem}>
      Prisijungti
    </a>
  </div>
);

export const DropdownMenu = (props: any) => {
  const { loggedIn, ...restProps } = props;
  const logIn = async () => {
    window.location.assign(`${process.env.REACT_APP_BACKEND_URL}/auth/google/login`);
  };

  const logOut = async () => {
    axios({
      method: 'get',
      withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': process.env.REACT_APP_FRONTEND_URL,
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/auth/logout`,
    }).then((response) => {
      window.location.reload();
    });
  };
  return (
    <div className={styles.dropdownContainer} {...restProps}>
      {loggedIn ? loggedInList(logOut) : notLoggedInList(logIn)}
    </div>
  );
};

export default DropdownMenu;
