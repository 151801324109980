import * as React from 'react';
import { ProfileImage, ButtonFullWidth } from '../../components';
import styles from './trainer.module.scss';
import { PageBottomWrapper, PageWrapper } from '../../components/page';
import { useUserData } from '../../../data.providers/user.data.context';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

interface Trainer {
  displayName: string;
  email: string;
  pictureUrl: string;
  phoneNumber: string;
}

export const Trainer: React.FC = () => {
  const { user, updateUser } = useUserData();
  const [trainer, setTrainer] = React.useState<Trainer>();
  let { trainerId } = useParams();
  const navigate = useNavigate();

  const login = `${process.env.REACT_APP_BACKEND_URL}/auth/google/login`;

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/user/trainer/${trainerId}`,
    })
      .then((response) => {
        setTrainer(response.data);
      })
      .catch((e) => {
        navigate('/');
      });
  }, []);

  return (
    <div className={styles.trainerContainer}>
      <PageWrapper>
        <div className={styles.trainerImageSection}>
          {trainer?.pictureUrl && <ProfileImage path={trainer.pictureUrl} />}
        </div>
        <div className={styles.trainerNameSection}>
          <div className={styles.trainerName}>{trainer?.displayName}</div>
        </div>
      </PageWrapper>
      <PageBottomWrapper>
        {user && <ButtonFullWidth name="Registruokis į treniruotę" path={`/t/${trainerId}/calendar`}></ButtonFullWidth>}
        {!user && <ButtonFullWidth name="Prisijungti" path={login}></ButtonFullWidth>}
      </PageBottomWrapper>
    </div>
  );
};
