import React from 'react';
import styles from './weekday.with.times.module.scss';

interface IDayTime {
  dayTime: {
    day: string;
    times: string[][];
  };
}

export const WeekdayWithTimes: React.FC<IDayTime> = ({ dayTime }) => {
  return (
    <div className={styles.weekdayWithTimes}>
      <div className={styles.day}>{dayTime.day}</div>
      <div className={styles.times}>{dayTime.times.map((time) => ` ${time.join('-')}`)}</div>
    </div>
  );
};
