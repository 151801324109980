import React from 'react';
import { Link } from 'react-router-dom';
import styles from './training.type.card.module.scss';

interface TraingType {
  title: string;
  description: string;
  path: string;
}

export const TrainingTypeCard: React.FC<TraingType> = ({ title, description, path }) => {
  return (
    <Link className={styles.trainingTypeCard} to={path}>
      <h3 className={styles.trainingTypeTitle}>{title}</h3>
      <p className={styles.trainingTypeDescription}>{description}</p>
    </Link>
  );
};
