import React from 'react';
import styles from './location.item.module.scss';
import { Frame } from '../frame';
import { ReactComponent as CancelSvg } from './cancel.svg';

interface ILocation {
  id: string;
  displayName: string;
}

interface ILocationItem {
  location: ILocation;
  removeLocation: (id: string) => void;
}

export const LocationItem: React.FC<ILocationItem> = ({ location, removeLocation }) => {
  function handleRemoveLocation() {
    removeLocation(location.id);
  }

  return (
    <Frame className={styles.locationItem}>
      {location.displayName}
      <button onClick={handleRemoveLocation} className={styles.removeButton}>
        <CancelSvg />
      </button>
    </Frame>
  );
};
