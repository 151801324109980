import React, { ChangeEvent } from 'react';
import { ReactComponent as InputActive } from './input-active.svg';
import { ReactComponent as InputInactive } from './input-inactive.svg';
import styles from './checkbox.module.scss';
import classNames from 'classnames';

interface ICheckBox {
  label: string;
  value: number | string;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  className?: string;
}

export const Checkbox: React.FC<ICheckBox> = ({ label, value, onChange, checked, className }) => {
  return (
    <label className={classNames(styles.checkboxInputWrapper, className)}>
      <input type="checkbox" className={styles.checkboxInput} value={value} onChange={onChange} checked={checked} />
      <span className={styles.checkboxCustom}>
        <InputInactive className={styles.inactive} />
        <InputActive className={styles.active} />
      </span>
      {label}
    </label>
  );
};
