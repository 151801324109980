import * as React from 'react';
import checkSvg from './check.svg';
import closeSvg from './close.svg';
import styles from './icon.with.text.module.scss';
import classNames from 'classnames';

interface IIconWithText {
  icon: 'success' | 'close';
  header: string;
  secondary: string | React.ReactNode;
  info?: string | React.ReactNode;
  className?: string;
  style?: any;
}

export const IconWithText: React.FC<IIconWithText> = ({
  icon = 'success',
  header,
  secondary,
  info,
  className,
  style,
}) => (
  <div className={classNames(styles.iconWithTextContainer, className)} style={{ ...(style || {}) }}>
    {<img src={icon === 'success' ? checkSvg : closeSvg} alt="check" />}
    <div className={styles.headerWrapper}>{header}</div>
    <div className={styles.InfoWrapper}>{info}</div>
    <div className={styles.SecondaryWrapper}>{secondary}</div>
  </div>
);
