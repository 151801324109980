import React, { useState } from 'react';
import styles from './location.create.module.scss';
import { PageTitle, PageWrapper } from '../../components/page';
import { ButtonFullWidth, TextInput } from '../../components';

export const LocationCreate = () => {
  return (
    <PageWrapper>
      <PageTitle backButton>Nauja lokacija</PageTitle>
      <form>
        <TextInput title="Pavadinimas" />
        <TextInput title="Adresas" />
        <div className={styles.locationButtonWrapper}>
          <ButtonFullWidth name="Pateikti užklausą" onClick={() => {}} />
        </div>
      </form>
    </PageWrapper>
  );
};
