import React from 'react';
import styles from './trainer.order.module.scss';
import { PageTitle, PageWrapper } from '../../../components/page';
import { ButtonWithIcon, Field, Status, Title, UserCard } from '../../../components';

import { PaymentTraining } from '../../../components/payment/payment.training';
import { dateFormat } from '../../../utils/date.format.util';
import { PaymentTotalPrice } from '../../../components/payment';
// import { ReactComponent as LoopSvg } from "./loop.svg";
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface User {
  id: string;
  email: string;
  displayName: string;
  pictureUrl: string;
  phoneNumber: string;
}

interface Timeslot {
  id: string;
  date: string;
  time: string;
  price: number;
  address: string;
  status: string;
}

interface Order {
  id: string;
  status: 'success' | 'pending' | 'failed';
  user: User;
  createdAt: string;
  timeSlots: Array<Timeslot>;
}

const getOrderTotalSum = (selectedTimeslots: Timeslot[]): number => {
  return selectedTimeslots.length ? selectedTimeslots.map((a) => a.price).reduce((a, b) => a + b) : 0;
};

const getTraningsLithuanianTranslation = (count: number): string => {
  switch (count % 10) {
    case 1:
      return 'treniruotė';
    case 0:
      return 'treniruočių';
    default:
      return 'treniruotės';
  }
};

const getStatusLithuanianTranslation = {
  success: 'Apmokėta sėkmingai',
  pending: 'Laukiama apmokėjimo',
  failed: 'Apmokėjimas nepavyko',
};

export const TrainerOrder = () => {
  const [order, setOrder] = React.useState<Order>();
  let { orderId } = useParams();

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/order/trainer-order-details/${orderId}`,
      withCredentials: true,
      params: {},
    }).then((response) => {
      if (response.data.msg) {
        console.log('Not Authenticated');
      } else {
        setOrder(response.data);
      }
    });
  }, []);

  return (
    <PageWrapper className={'navbarPadding'}>
      <PageTitle backButton>Mokėjimas</PageTitle>
      {order && (
        <>
          <div className={styles.orderDetails}>
            <div className={styles.infoWrapper}>
              <Title size="big" className={styles.orderDetailsCount}>
                {order.timeSlots.length} {getTraningsLithuanianTranslation(order.timeSlots.length)}
              </Title>
              <Field
                size="big"
                icon={<Status status={order.status} />}
                text={getStatusLithuanianTranslation[order.status]}
              />
              <div className={styles.infoText}>Užsakymas pateiktas {dateFormat(order.createdAt, true)}</div>
            </div>
            <UserCard user={order.user} />
            {/* <ButtonWithIcon icon={<LoopSvg />} name='Peržiūrėti abonimentą' /> */}
          </div>
          {order.timeSlots.map((item: Timeslot, index) => (
            <PaymentTraining userType="trainer" timeSlot={item} key={index} />
          ))}
          <PaymentTotalPrice price={getOrderTotalSum(order.timeSlots)} />
        </>
      )}
    </PageWrapper>
  );
};
