import React, { ChangeEvent } from 'react';
import styles from './day.input.module.scss';
import { ReactComponent as RemoveSvg } from './remove.svg';
import { TextInput } from '../input';
import { ButtonFullWidth } from '../button';
import classNames from 'classnames';
import { Checkbox } from '../checkbox';

type TDay = 0 | 1 | 2 | 3 | 4 | 5 | 6;
interface Isubscription {
  day: TDay;
  times: string[];
}
interface DayData {
  label: string;
  value: TDay;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  subscriptions: Isubscription[];
  addTimeInput: (value: TDay) => void;
  removeTimeInput: (value: TDay, index: number) => void;
  handleTimeInputValue: (data: { day: TDay; value: string; index: number }) => void;
}
interface IRemoveButton {
  onClick: (index: number) => void;
  index: number;
}

const RemoveButton: React.FC<IRemoveButton> = ({ onClick, index }) => {
  function handleClick() {
    onClick(index);
  }

  return (
    <button className={styles.removeButton} onClick={handleClick}>
      <RemoveSvg />
    </button>
  );
};

export const DayInput: React.FC<DayData> = ({
  label,
  value,
  onChange,
  subscriptions,
  addTimeInput,
  removeTimeInput,
  handleTimeInputValue,
}) => {
  const dayData = subscriptions.find((item) => item.day === value);

  function handleAddTimeInput() {
    addTimeInput(value);
  }

  function handleRemoveTimeInput(index: number) {
    removeTimeInput(value, index);
  }

  function handleChange(ev: ChangeEvent<HTMLInputElement>) {
    handleTimeInputValue({ day: value, value: ev.target.value, index: +(ev.target.dataset.index || '0') });
  }

  return (
    <div className={styles.dayInputContainer}>
      <Checkbox label={label} value={value} onChange={onChange} checked={Boolean(dayData)} />
      {Boolean(dayData) && (
        <div className={styles.timeInputsContainer}>
          <div className={styles.timeInputsWrapper}>
            {dayData?.times.map((time, index) => (
              <div
                className={classNames(styles.timeInputWrapper, { [styles.multi]: dayData?.times.length > 1 })}
                key={index}
              >
                <TextInput
                  title="Laikas"
                  className={classNames(styles.timeInput, { [styles.multi]: index > 0 })}
                  placeholder="HH:MM"
                  value={time}
                  onChange={handleChange}
                  data-index={index}
                  afterIcon={index > 0 && <RemoveButton onClick={handleRemoveTimeInput} index={index} />}
                />
              </div>
            ))}
          </div>
          <ButtonFullWidth
            onClick={handleAddTimeInput}
            name="Pridėti dar vieną laiką"
            styleType="outlined"
            size="small"
          />
        </div>
      )}
    </div>
  );
};
