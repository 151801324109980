import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './tabs.module.scss';

interface ITabItem {
  index: number;
  label: string | ReactNode;
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

const TabItem: React.FC<ITabItem> = ({ index, label, activeIndex, setActiveIndex }) => {
  function selectTab() {
    setActiveIndex(index);
  }
  return (
    <button
      key={index}
      className={classNames(styles.tabItem, { [styles.active]: index === activeIndex })}
      onClick={selectTab}
    >
      {label}
    </button>
  );
};

export default TabItem;
