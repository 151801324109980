import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './location.modal.module.scss';
import { Modal } from '../modal';
import { LocationInput } from './location.input';
import { ButtonFullWidth } from '../button';
import { TextInput } from '../input';

interface ILocation {
  id: string;
  displayName: string;
}

interface ITrainingData {
  location: ILocation;
  price: string;
}

interface ILocationModal {
  isOpen: boolean;
  onClose: () => void;
  handleLocationSelector: (data: ITrainingData) => void;
  trainingData: ITrainingData;
}

export const LocationModal: React.FC<ILocationModal> = ({ isOpen, onClose, handleLocationSelector, trainingData }) => {
  const [location, setLocation] = useState<ILocation>();
  const [price, setPrice] = useState('');

  function onSelect() {
    if (location) {
      handleLocationSelector({
        location,
        price: price,
      });
    }
  }

  function handlePrice(ev: ChangeEvent<HTMLInputElement>) {
    setPrice(ev.target.value);
  }

  function handleLocationSelect(location: ILocation) {
    setLocation(location);
  }

  useEffect(() => {
    if (trainingData?.location) {
      setLocation(trainingData.location);
    }

    if (trainingData?.price) {
      setPrice(trainingData.price);
    }
  }, [trainingData]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.locationModal}>
        <div className={styles.title}>Pasirinkite Lokaciją</div>
        <LocationInput
          location={{
            id: '1',
            displayName: 'SportGates',
          }}
          handleLocationSelect={handleLocationSelect}
          checked={location?.id === '1'}
        />

        <LocationInput
          location={{
            id: '2',
            displayName: 'Lemon Gym',
          }}
          handleLocationSelect={handleLocationSelect}
          checked={location?.id === '2'}
        />

        <ButtonFullWidth name="Redaguoti lokacijas" styleType="outlined" path="/location/edit" size="small" />

        <div className={styles.priceForm}>
          <div className={styles.title}>Pasirinkite kainą</div>
          <TextInput title="Kaina, eur" onChange={handlePrice} value={price} />
        </div>

        <div className={styles.buttonsWrapper}>
          <ButtonFullWidth name="Išsaugoti" onClick={onSelect} />
          <ButtonFullWidth name="Uždaryti" onClick={onClose} styleType="outlined" />
        </div>
      </div>
    </Modal>
  );
};
