import { Title } from '../title';

interface IPaymentTrainingsCounter {
  count: number;
}

const getTraningsLithuanianTranslation = (count: number): string => {
  switch (count % 10) {
    case 1:
      return 'treniruotė';
    case 0:
      return 'treniruočių';
    default:
      return 'treniruotės';
  }
};

export const PaymentTrainingsCounter: React.FC<IPaymentTrainingsCounter> = ({ count }) => (
  <Title size="big">
    {count} {getTraningsLithuanianTranslation(count)}
  </Title>
);
