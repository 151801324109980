import * as React from 'react';
import styles from './header.module.scss';
import loginImage from './login.svg';
import { DropdownMenu } from '../dropdown.menu';
import { Link } from 'react-router-dom';
import { useUserData } from '../../../data.providers/user.data.context';
import { resolveImgUrl } from '../../utils/url.resolve.util';

export const AppHeader: React.FC = () => {
  const { user } = useUserData();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <Link to="/">eTreneris</Link>
      </div>
      <div className={styles.logins}>
        {!user ? (
          <img
            className={styles.loginAvatar}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            width={35}
            height={35}
            alt="login"
            src={loginImage}
          />
        ) : (
          <img
            className={styles.profileAvatar}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            width={35}
            height={35}
            alt="profile"
            src={resolveImgUrl(user.pictureUrl)}
          />
        )}
        {isDropdownOpen &&
          (!user ? (
            <DropdownMenu
              onClick={() => {
                setIsDropdownOpen(false);
              }}
              loggedIn={false}
            />
          ) : (
            <DropdownMenu
              onClick={() => {
                setIsDropdownOpen(false);
              }}
              loggedIn={true}
            />
          ))}
      </div>
    </div>
  );
};
