import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { USER_TYPE } from '../../enums/user.type.enum';
import { AppPaths } from './app.paths.enum';
import { useUserData } from '../../../data.providers/user.data.context';
import { userRoutes } from './routes/routes.user';
import { trainerRoutes } from './routes/routes.trainer';
import { publicRoutes } from './routes/routes.public';

const getGeneratedRoutes = (routesArray: Array<{ path: string; element: React.FC }>) => {
  return routesArray.map(({ path, element }) => (
    <Route key={path} path={path} element={React.createElement(element)} />
  ));
};

const getRedirects = (user: any) => {
  if (user?.type === USER_TYPE.TRAINER) {
    return <Route path="/" element={<Navigate to={AppPaths.TRAINER_CALENDAR} />} />;
  }
  return <Route path="/" element={<Navigate to="/t/a455e82a-64dd-43f8-997a-6e77c6f14a28" />} />;
};

export const AppRoutes: React.FC = () => {
  const { user } = useUserData();
  if (user === null) {
    return <></>;
  }

  const generatedRoutes =
    user?.type === USER_TYPE.TRAINER ? getGeneratedRoutes(trainerRoutes) : getGeneratedRoutes(userRoutes);

  return (
    <Routes>
      {getGeneratedRoutes(publicRoutes)}
      {generatedRoutes}
      {getRedirects(user)}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
