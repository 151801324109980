import React from 'react';
import styles from './title.module.scss';
import classNames from 'classnames';

interface ITitle {
  children: React.ReactNode;
  size: 'small' | 'big';
  className?: string;
}

export const Title: React.FC<ITitle> = ({ children, size, className }) => {
  return <div className={classNames(styles.title, styles[size], className)}>{children}</div>;
};
