import React from 'react';
import styles from './trainer.time.selector.module.scss';
import { PageTitle, PageWrapper } from '../../components/page';
import { HourRow, LocationCard, LocationModal } from '../../components';
import { CalendarDays } from '../calendar/calendar.days';

interface ILocation {
  id: string;
  displayName: string;
}

interface ITrainingData {
  location: ILocation;
  price: string;
}

interface ITimeRange {
  start: string;
  end: string;
  location: ILocation;
  price: string;
}

const generateTimeSlots = () => {
  const times = [];
  for (let hour = 9; hour < 17; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 15) {
      const hourString = hour.toString().padStart(2, '0');
      const minuteString = minutes.toString().padStart(2, '0');
      times.push(`${hourString}:${minuteString}`);
    }
  }
  return [...times, '17:00'];
};

function getAllTimesFromRange(arr: string[], startValue: string, endValue: string) {
  const startIndex = arr.indexOf(startValue);
  const endIndex = arr.indexOf(endValue);

  // Check if both values exist in the array
  if (startIndex === -1 || endIndex === -1) {
    return []; // return an empty array if either value doesn't exist
  }

  // Slice the array from startIndex to endIndex + 1
  return arr.slice(startIndex, endIndex + 1);
}

export const TrainerTimeSelector = () => {
  const [activeDate, setActiveDate] = React.useState(new Date());
  // you can pass initial value in timeRanges for example [{ start: '10:00', end: '12:00' }, { start: '13:00', end: '14:00' }]
  const [timeRanges, setTimeRanges] = React.useState<ITimeRange[]>([]);
  const [currentRange, setCurrentRange] = React.useState<{ start: string | null; end: string | null }>({
    start: null,
    end: null,
  });
  const [timeMarkedToRemove, setTimeMarkedToRemove] = React.useState('');

  const [trainingData, setTrainingData] = React.useState<ITrainingData>({
    location: {
      id: '1',
      displayName: 'SportGates',
    },
    price: '30',
  });
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  function openPopup() {
    setIsPopupOpen(true);
  }

  function closePopup() {
    setIsPopupOpen(false);
  }

  function handleLocationSelector(data: ITrainingData) {
    setTrainingData(data);
    closePopup();
  }

  const times = generateTimeSlots();

  const handleTimeClick = (time: string) => {
    const { start, end } = currentRange;

    // Check if the clicked time is part of any existing range
    const overlappingRangeIndex = timeRanges.findIndex((range) => time >= range.start && time <= range.end);

    const timeMarkedToRemoveOverLappingIndex = timeMarkedToRemove
      ? timeRanges.findIndex((range) => timeMarkedToRemove >= range.start && timeMarkedToRemove <= range.end)
      : -1;

    const isRangeEdge = timeRanges.findIndex((range) => time === range.start || time === range.end);

    if (isRangeEdge !== -1) {
      setTimeMarkedToRemove(time);
    }

    if ((overlappingRangeIndex !== -1 || timeMarkedToRemoveOverLappingIndex !== -1) && !start) {
      setTimeMarkedToRemove(time);
      const existingRange = timeRanges[overlappingRangeIndex];
      let newRanges = [...timeRanges];
      if (timeMarkedToRemove === time) {
        // Split the existing range if time falls within it
        if (time > existingRange.start && time < existingRange.end) {
          const newTimeStart = times[times.indexOf(time) + 1];

          const newRange1 = { ...existingRange, start: existingRange.start, end: time };
          const newRange2 = { ...existingRange, start: newTimeStart, end: existingRange.end };
          const timesOfRange = getAllTimesFromRange(times, existingRange.start, existingRange.end);
          if (timesOfRange.length === 3) {
            newRanges.splice(overlappingRangeIndex, 1, { ...existingRange, start: existingRange.start, end: time });
          } else {
            newRanges.splice(overlappingRangeIndex, 1, newRange1, newRange2);
          }
          // Insert the new ranges into the state, removing the old one
        } else {
          // If the selected time is at the boundary, remove that range
          const newTimeRange = [...times]
            .slice(times.indexOf(existingRange.start), times.indexOf(existingRange.end) + 1)
            .filter((item) => item !== time);
          if (newTimeRange.length > 0) {
            newRanges = newRanges.map((item) => {
              if (item.start === existingRange.start && item.end === existingRange.end) {
                return { ...item, start: newTimeRange[0], end: newTimeRange[newTimeRange.length - 1] };
              }
              return item;
            });
          }
        }
        newRanges = newRanges.filter((item) => item.start !== item.end);
        setTimeMarkedToRemove('');
        setTimeRanges(newRanges);
        return;
      } else if (Boolean(timeMarkedToRemove)) {
        const timeMarkedToRemoveOverLappingIndex = timeRanges.findIndex(
          (range) => timeMarkedToRemove >= range.start && timeMarkedToRemove <= range.end
        );

        const timeOverLappingIndex = timeRanges.findIndex((range) => time >= range.start && time <= range.end);
        // && (((timeRanges[overlappingRangeIndex] || timeRanges[timeMarkedToRemoveOverLappingIndex])?.location?.id === trainingData.location.id) && (timeRanges[overlappingRangeIndex] || timeRanges[timeMarkedToRemoveOverLappingIndex])?.price === trainingData.price)
        if (timeMarkedToRemoveOverLappingIndex !== -1 && timeOverLappingIndex !== -1) {
          if (timeMarkedToRemoveOverLappingIndex === timeOverLappingIndex) {
            const newRange1 = {
              ...existingRange,
              start: existingRange.start,
              end: timeMarkedToRemove > time ? time : timeMarkedToRemove,
            };
            const newRange2 = {
              ...existingRange,
              start: timeMarkedToRemove > time ? timeMarkedToRemove : time,
              end: existingRange.end,
            };
            newRanges.splice(overlappingRangeIndex, 1, newRange1, newRange2);
          } else {
            const markedRange = timeRanges[timeMarkedToRemoveOverLappingIndex];
            if (
              markedRange.location.id === trainingData.location.id &&
              markedRange.price === trainingData.price &&
              existingRange.location.id === trainingData.location.id &&
              existingRange.price &&
              trainingData.price
            ) {
              const newRange = {
                ...existingRange,
                start: markedRange.start > existingRange.start ? existingRange.start : markedRange.start,
                end: markedRange.end > existingRange.end ? markedRange.end : existingRange.end,
              };
              if (markedRange.start < existingRange.start) {
                newRanges.splice(overlappingRangeIndex, 1);
                newRanges.splice(timeMarkedToRemoveOverLappingIndex, 1, newRange);
              } else {
                newRanges.splice(timeMarkedToRemoveOverLappingIndex, 1);
                newRanges.splice(overlappingRangeIndex, 1, newRange);
              }
            } else {
              const isRangeEdge = timeRanges.findIndex(
                (range) => timeMarkedToRemove === range.start || timeMarkedToRemove === range.end
              );
              const isTimeRangeEdge = timeRanges.findIndex((range) => time === range.start || time === range.end);
              if (isRangeEdge !== -1 && isTimeRangeEdge !== -1) {
                const newRange = {
                  ...trainingData,
                  start: existingRange.start > markedRange.start ? markedRange.end : existingRange.end,
                  end: existingRange.end > markedRange.end ? existingRange.start : markedRange.start,
                };
                newRanges = [...newRanges, newRange];
              }
            }
          }
        }
        if (timeMarkedToRemoveOverLappingIndex !== -1 && timeOverLappingIndex === -1) {
          const markedRange = timeRanges[timeMarkedToRemoveOverLappingIndex];
          if (markedRange.location.id === trainingData.location.id && markedRange.price === trainingData.price) {
            const newRange = {
              ...markedRange,
              start: timeMarkedToRemove > time ? time : markedRange.start,
              end: timeMarkedToRemove > time ? markedRange.end : time,
            };
            newRanges.splice(timeMarkedToRemoveOverLappingIndex, 1, newRange);
          } else {
            const newRange1 = {
              ...markedRange,
              start: timeMarkedToRemove > time ? timeMarkedToRemove : markedRange.start,
              end: timeMarkedToRemove > time ? markedRange.end : timeMarkedToRemove,
            };
            const newRange2 = {
              ...trainingData,
              start: timeMarkedToRemove > time ? time : timeMarkedToRemove,
              end: timeMarkedToRemove > time ? timeMarkedToRemove : time,
            };
            newRanges.splice(timeMarkedToRemoveOverLappingIndex, 1, newRange1, newRange2);
          }
        }
        newRanges = newRanges.filter((item) => item.start !== item.end);
        setTimeMarkedToRemove('');
        setTimeRanges(newRanges);
      }

      return;
    }
    if (!start) {
      // Set start time
      setCurrentRange({ start: time, end: null });
    } else if (!end) {
      // Set end time
      const newRange = {
        start: start < time ? start : time,
        end: start < time ? time : start,
        ...trainingData,
      };

      // Check for overlapping ranges and handle deselection
      const modifiedRanges = timeRanges.reduce(
        (acc, range) => {
          const rangeTimes = [...times].slice(times.indexOf(range.start), times.indexOf(range.end) + 1);
          const newRangeTimes = [...times].slice(times.indexOf(newRange.start), times.indexOf(newRange.end) + 1);
          const hasCommonValues = rangeTimes.find((value) => newRangeTimes.includes(value));
          const filteredTime = rangeTimes.filter((value) => !newRangeTimes.includes(value));
          const startMatches = timeRanges.find((value) => value.end === newRange.start);
          const endMatches = timeRanges.find((value) => value.start === newRange.end);
          if (
            (Boolean(startMatches) || Boolean(endMatches)) &&
            !(range?.location.id === newRange.location.id && range?.price === newRange.price)
          ) {
            acc = {
              ...acc,
              hasCommonValues: false,
            };
          } else if (Boolean(startMatches) && Boolean(endMatches)) {
            if (!acc.timeRanges.find((item) => item.start === startMatches?.start && item.end === endMatches?.end)) {
              if (
                endMatches?.location.id === newRange.location.id &&
                endMatches?.price === newRange.price &&
                startMatches?.location.id === newRange.location.id &&
                startMatches?.price === newRange.price
              ) {
                acc = {
                  hasCommonValues: true,
                  timeRanges: [
                    ...acc.timeRanges.filter((item) => {
                      return item.end !== newRange.start && item.start !== newRange.end;
                    }),
                    { ...newRange, start: startMatches?.start || '', end: endMatches?.end || '' },
                  ],
                };
              } else {
                acc = {
                  ...acc,
                  hasCommonValues: false,
                };
              }
            }
          } else if (hasCommonValues) {
            if (filteredTime.length > 0) {
              if (range.location.id === newRange.location.id && range.price === newRange.price) {
                acc = {
                  hasCommonValues: true,
                  timeRanges: [
                    ...acc.timeRanges.map((item) => {
                      if (item.start > newRange.start && item.start === newRange.end) {
                        if (!timeRanges.find((range) => newRange.start >= range.start && newRange.start <= range.end)) {
                          return {
                            ...item,
                            start: newRange.start,
                            end: item.end,
                          };
                        }
                      } else if (item.start < newRange.start && item.end === newRange.start) {
                        if (!timeRanges.find((range) => newRange.end >= range.start && newRange.end <= range.end)) {
                          return {
                            ...item,
                            start: item.start,
                            end: newRange.end,
                          };
                        }
                      }

                      return item;
                    }),
                  ],
                };
              }

              acc = {
                hasCommonValues: true,
                timeRanges: [
                  ...acc.timeRanges.map((item) => {
                    if (range.start === item.start && item.end === range.end) {
                      if (item.start > newRange.start) {
                        return {
                          ...item,
                          start: times[times.indexOf(filteredTime[0]) - 1],
                          end: filteredTime[filteredTime.length - 1],
                        };
                      }
                      if (item.start === newRange.start) {
                        const endIndex = times.indexOf(filteredTime[filteredTime.length - 2]);
                        return {
                          ...item,
                          start: times[times.indexOf(filteredTime[0]) - 1],
                          end: times[
                            endIndex !== -1 ? endIndex + 1 : times.indexOf(filteredTime[filteredTime.length - 1])
                          ],
                        };
                      }
                      return {
                        ...item,
                        start: filteredTime[0],
                        end: times[times.indexOf(filteredTime[filteredTime.length - 1]) + 1],
                      };
                    }
                    return item;
                  }),
                ],
              };
            } else {
              acc = {
                hasCommonValues: true,
                timeRanges: [
                  ...acc.timeRanges.filter((item) => {
                    return !(range.start === item.start && item.end === range.end);
                  }),
                ],
              };
            }
          }
          return acc;
        },
        { hasCommonValues: false, timeRanges }
      );

      if (timeRanges.length > 0) {
        if (modifiedRanges.hasCommonValues) {
          setTimeMarkedToRemove('');
          setTimeRanges(modifiedRanges.timeRanges);
        } else {
          if (newRange.start !== newRange.end) {
            setTimeMarkedToRemove('');
            setTimeRanges((prev) => [...prev, newRange]);
          }
        }
      } else {
        setTimeRanges([newRange]);
        setTimeMarkedToRemove('');
      }

      // Clear the current range after selection
      setCurrentRange({ start: null, end: null });
    }
  };
  console.log('timeRanges', timeRanges);

  return (
    <PageWrapper>
      <PageTitle backButton>Treniruočių laikai</PageTitle>
      <LocationCard location={trainingData.location} onClick={openPopup} price={trainingData.price} />
      <LocationModal
        isOpen={isPopupOpen}
        onClose={closePopup}
        handleLocationSelector={handleLocationSelector}
        trainingData={trainingData}
      />
      <div className={styles.timeSelectorWrapper}>
        <CalendarDays setActiveDate={setActiveDate} />
        {times.map((time) => {
          const timeRange = timeRanges.find((range) => time >= range.start && time <= range.end);
          const startTime = timeRanges.find((range) => time === range.start);
          const isStart = currentRange.start === time || timeRanges.some((range) => time === range.start);
          const isEnd = timeRanges.some((range) => time === range.end);
          return (
            <HourRow
              key={time}
              hour={time}
              onClick={handleTimeClick}
              isSelected={Boolean(timeRange)}
              colorDown={isStart}
              colorUp={isEnd}
              location={startTime?.location?.displayName}
              price={startTime?.price}
              isMarkedForRemove={timeMarkedToRemove === time}
            />
          );
        })}
      </div>
    </PageWrapper>
  );
};
