import { Title } from '..';

interface IOrderCounter {
  count: number;
}

const getTraningsLithuanianTranslation = (count: number): string => {
  switch (count % 10) {
    case 1:
      return 'Mokėjimas';
    case 0:
      return 'Mokėjimų';
    default:
      return 'Mokėjimai';
  }
};

export const OrdersCounter: React.FC<IOrderCounter> = ({ count }) => (
  <Title size="small">
    {count} {getTraningsLithuanianTranslation(count)}
  </Title>
);
