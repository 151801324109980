import * as React from 'react';
import { UserDataContext } from './user.data.context';

export const useUserData = () => {
  const context = React.useContext(UserDataContext);

  if (context === undefined) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }

  return context;
};
