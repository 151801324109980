import React from 'react';
import styles from './back.button.module.scss';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from './chevron-left.svg';

export const BackButton: React.FC = () => {
  const navigate = useNavigate();

  function handleBackButton() {
    navigate(-1);
  }

  return (
    <button className={styles.backButton} onClick={handleBackButton}>
      <ChevronLeft />
    </button>
  );
};
