import React from 'react';
import { PageBottomWrapper, PageWrapper } from '../../components/page';
import { IconWithText } from '../../components/icon.with.text';
import { ButtonFullWidth } from '../../components';
import { Checkbox } from '../../components/checkbox';
import styles from './trainer.training.cancellation.module.scss';

export const TrainerTrainingCancellation = () => {
  return (
    <>
      <PageWrapper>
        <IconWithText
          icon="close"
          header="Ar tikrai norite atšaukti treniruotę?"
          info={
            <>
              15:00 Penktadienis, Gegužės 10
              <br />
              Kostas Petrošius, SportGates
            </>
          }
          style={{ height: `calc(${window.innerHeight}px - 194px)` }}
          secondary={
            <Checkbox
              label="Išimti treniruotę iš laisvų laikų"
              value={1}
              onChange={() => {}}
              className={styles.freeTimeCheckbox}
            />
          }
          className={styles.cancellationInfo}
        />
      </PageWrapper>
      <PageBottomWrapper>
        <ButtonFullWidth name="Taip, atšaukti" onClick={() => {}} />
        <ButtonFullWidth name="Ne, neatšaukti" onClick={() => {}} styleType="outlined" size="small" />
      </PageBottomWrapper>
    </>
  );
};
