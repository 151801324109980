import classNames from 'classnames';
import { FrameButton } from '../button';
import { Frame } from '../frame';
import styles from './payment.training.module.scss';

interface Timeslot {
  id: string;
  date: string;
  time: string;
  price: number;
  address: string;
}

interface IPaymentTraining {
  timeSlot: Timeslot;
  userType: 'trainer' | 'client';
}

const monthsInLithuanian = [
  'Sausio',
  'Vasario',
  'Kovo',
  'Balandžio',
  'Gegužės',
  'Birželio',
  'Liepos',
  'Rugpjūčio',
  'Rugsėjo',
  'Spalio',
  'Lapkričio',
  'Gruodžio',
];

const weekDaysInLithuanian = ['Sk', 'Pr', 'An', 'Tr', 'Kt', 'Pn', 'Št'];

const convertDateForIos = (date: any) => {
  const arr = date.split(/[- :]/);
  date = new Date(arr[0], arr[1] - 1, arr[2]);
  return date;
};

const formatTimeString = (time: string) => time.slice(0, -3);

const getWeekDay = (date: string) => weekDaysInLithuanian[convertDateForIos(date).getDay()];

const getDateInText = (date: string) =>
  `${monthsInLithuanian[convertDateForIos(date).getMonth()]} ${convertDateForIos(date).getDate()}`;

const getYear = (date: string) => `${convertDateForIos(date).getFullYear()}`;

export const PaymentTraining: React.FC<IPaymentTraining> = ({ timeSlot, userType }) => (
  <>
    <Frame className={classNames(styles.paymentTraining, styles[userType])}>
      <div className={styles.item}>
        <div className={styles.name}>Laikas</div>
        <div className={styles.value}>{formatTimeString(timeSlot.time)}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.name}>Data</div>
        <div className={styles.value}>
          {userType === 'client' && `${getWeekDay(timeSlot.date)},`} {getDateInText(timeSlot.date)},{' '}
          {getYear(timeSlot.date)}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.name}>Vieta</div>
        <div className={styles.value}>{timeSlot.address}</div>
      </div>
      <div className={styles.item}>
        <div className={styles.name}>Kaina</div>
        <div className={styles.value}>&#8364; {timeSlot.price}</div>
      </div>
    </Frame>
    {userType === 'trainer' && (
      <FrameButton
        className={styles.paymentTrainingButton}
        name="Peržiūrėti treniruotę"
        path={'/training/' + timeSlot.id}
      />
    )}
  </>
);
