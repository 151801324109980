import React from 'react';
import styles from './hour.row.module.scss';
import classNames from 'classnames';

interface IHourRow {
  hour: string;
  onClick: (hour: string) => void;
  isSelected: boolean;
  colorDown: boolean;
  colorUp: boolean;
  isMarkedForRemove: boolean;
  location?: string;
  price?: string;
}

export const HourRow: React.FC<IHourRow> = ({
  hour,
  onClick,
  isSelected,
  colorDown,
  colorUp,
  location,
  price,
  isMarkedForRemove,
}) => {
  function handleClick() {
    onClick(hour);
  }

  return (
    <div
      className={classNames(styles.hourRow, {
        [styles.selected]: isSelected,
        [styles.colorDown]: colorDown,
        [styles.colorUp]: colorUp,
      })}
      onClick={handleClick}
    >
      <div className={styles.topPart} />
      <hr className={classNames(styles.separator, { [styles.isMarkedForRemove]: isMarkedForRemove })} />
      <div className={styles.downPart}>
        {hour}
        {location && (
          <div className={styles.info}>
            <div className={styles.location}>{location}</div>
            <div className={styles.price}>{price} €</div>
          </div>
        )}
      </div>
    </div>
  );
};
