import React, { useState } from 'react';
import { UserDataContext } from './user.data.context';

export const UserDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<any>(null);

  const updateUser = (userInfo: any) => {
    setUser(userInfo);
  };

  return <UserDataContext.Provider value={{ user, updateUser }}>{children}</UserDataContext.Provider>;
};
