import classNames from 'classnames';
import styles from './navbar.module.scss';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TrainersSVG } from './trainers.svg';
import { ReactComponent as TrainingsSVG } from './trainings.svg';
import { ReactComponent as SubscribsionsSVG } from './subscribsions.svg';
import { ReactComponent as OrdersSVG } from './orders.svg';

interface INavbar {
  className?: string;
  active: 'trainers' | 'trainings' | 'subscriptions' | 'orders';
}

export const Navbar: React.FC<INavbar> = React.forwardRef(({ className, active, ...restProps }: INavbar, ref) => {
  const navigate = useNavigate();
  const handleClick = (path: string) => {
    navigate(path);
  };

  return <></>;

  return (
    <div className={classNames(styles.navbarContainer, className)}>
      <div className={styles.navbarSidePaddings}>
        <div
          onClick={() => handleClick('/trainers')}
          className={classNames(styles.navbarItem, { [styles.active]: active === 'trainers' })}
        >
          <div className={styles.navbarIcon}>
            <TrainersSVG />
          </div>
          <div className={styles.navbarText}>Treneriai</div>
        </div>
        <div
          onClick={() => handleClick('/trainings')}
          className={classNames(styles.navbarItem, { [styles.active]: active === 'trainings' })}
        >
          <div className={styles.navbarIcon}>
            <TrainingsSVG />
          </div>
          <div className={styles.navbarText}>Treniruotės</div>
        </div>
        <div
          onClick={() => handleClick('/subscriptions')}
          className={classNames(styles.navbarItem, { [styles.active]: active === 'subscriptions' })}
        >
          <div className={styles.navbarIcon}>
            <SubscribsionsSVG />
          </div>
          <div className={styles.navbarText}>Abonementai</div>
        </div>
        <div
          onClick={() => handleClick('/orders')}
          className={classNames(styles.navbarItem, { [styles.active]: active === 'orders' })}
        >
          <div className={styles.navbarIcon}>
            <OrdersSVG />
          </div>
          <div className={styles.navbarText}>Mokėjimai</div>
        </div>
      </div>
    </div>
  );
});
