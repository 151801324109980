import React from 'react';
import { dateFormat } from '../../utils/date.format.util';
import styles from './order.info.module.scss';

interface IOrderInfo {
  name?: string;
  phoneNumber?: string;
  createdAt: string;
  preName: string;
}

export const OrderInfo: React.FC<IOrderInfo> = ({ name, phoneNumber, createdAt, preName }) => {
  return (
    <div className={styles.infoText}>
      {name && (
        <>
          {preName}: {name} {phoneNumber && <>({phoneNumber} )</>} <br />
        </>
      )}
      Mokėjimas pateiktas {dateFormat(createdAt, true)}
    </div>
  );
};
