import * as React from 'react';
import { Navbar } from '../../components';
import styles from './trainers.module.scss';
import { PageTitle, PageWrapper } from '../../components/page';

export const Trainers: React.FC = () => {
  return (
    <div className={styles.trainersContainer}>
      <PageWrapper className={'navbarPadding'}>
        <PageTitle>Treneriai</PageTitle>
      </PageWrapper>
      <Navbar active="trainers" />
    </div>
  );
};
