import React from 'react';
import styles from './user.card.module.scss';
import { ReactComponent as Phone } from './phone.svg';
import classNames from 'classnames';
import { Frame } from '../frame';
import { Link } from 'react-router-dom';
import { resolveImgUrl } from '../../utils/url.resolve.util';
interface IUser {
  id: string;
  displayName: string;
  pictureUrl: string;
  phoneNumber: string;
  location?: string;
  email?: string;
}

interface IUserCard {
  user: IUser;
  className?: string;
}

export const UserCard: React.FC<IUserCard> = ({ user, className }) => {
  return (
    <Frame className={classNames(styles.trainerCard, className)}>
      <div className={styles.trainerImageWrapper}>
        <img src={resolveImgUrl(user.pictureUrl)} className={styles.trainerImage} alt="profile" />
      </div>
      <div className={styles.trainerInfo}>
        <div className={styles.tainerNamer}>{user.displayName}</div>
        <div className={styles.trainerText}>{user.phoneNumber}</div>
        {user.location && <div className={styles.trainerText}>{user.location}</div>}
        {user.email && <div className={styles.trainerText}>{user.email}</div>}
      </div>
      <Link to={`tel:${user.phoneNumber}`} className={styles.callButton}>
        <Phone />
      </Link>
    </Frame>
  );
};
