import * as React from 'react';
import axios from 'axios';
import styles from './user.trainings.module.scss';
import { Navbar, TrainingCard } from '../../../components';
import { PageTitle, PageWrapper } from '../../../components/page';

interface Trainer {
  id: string;
  email: string;
  displayName: string;
  pictureUrl: object;
}

interface Training {
  id: string;
  date: string;
  time: string;
  address: string;
  trainer: Trainer;
}

export const UserTrainings: React.FC = () => {
  const [trainings, setTrainings] = React.useState<Array<Training>>([]);

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/timeslot/user-timeslots`,
      withCredentials: true,
    }).then((response) => {
      if (response.data.msg) {
        console.log('Not Authenticated');
      } else {
        setTrainings(response.data);
      }
    });
  }, []);

  return (
    <>
      <PageWrapper className={'navbarPadding'}>
        <PageTitle>Mano treniruotės</PageTitle>
        {trainings.map((item: Training) => (
          <TrainingCard training={item} userType="client" />
        ))}
      </PageWrapper>
      <Navbar active="trainings" />
    </>
  );
};
